import React from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import NoDataComponent from '../emptyContent/emptycontent';

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    maxWidth: 600,
    margin: '16px auto',
    background: "#1b1b1b",
    borderRadius: "16px"
}));

const ContentContainer = styled(CardContent)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: "#ffffff"
}));

const TopSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: "#ffffff"
}));

const ScoresContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    color: "#ffffff",
    gap:"12px",

}));

const ScoreBox = styled(Box)(({ theme }) => ({
    border: '2px solid',
    borderRadius: '4px',
    padding: '4px 8px',
    textAlign: 'center',
    width: '100px',
    color: "#ffffff"
}));

export default function Expectation({loading, projections, retryfetchProjections }) {
  if (projections.length === 0) {
    return (
      <NoDataComponent onRetry={retryfetchProjections} loading={loading} />
    )
  }

    return (
        <Grid container>
            {
              projections.map((item) => (
                <Grid lg={4} xs={12}
                sx={{
                    display:"flex",
                    justifyContent:"center",
                    padding:"12px"
                }}
                >
                    <StyledCard key={item.id}>
                        <ContentContainer>
                            <TopSection>
                                <Box>
                                    <Typography variant="h6">{item.title}</Typography>
                                </Box>
                            </TopSection>
                            <Typography variant="body2" mt={2}>
                                {item.description}
                            </Typography>
                            <ScoresContainer>
                                <ScoreBox
                                 sx={{
                                    background: "#393939",
                                     borderColor: "#393939" ,

                                     }}>
                                    {item.prize_picks_score}
                                </ScoreBox>
                                <ScoreBox sx={{ background: "#393939", borderColor: "#393939" }}>
                                    {item.underdog_score}
                                </ScoreBox>
                            </ScoresContainer>
                        </ContentContainer>
                    </StyledCard>
                </Grid>
              ))
            }
        </Grid>
    );
}
