import React, { useEffect, useState } from 'react';
import Lines from './lines/lines';
import Expectation from './expectation/expectation';
import { fetchleagues, fetchprojections } from '../services/websiteApis/apis';

function Home() {
    const [leagues, setLeagues] = useState([]);
    const [projections, setProjections] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const fetchLeaguesData = async () => {
            try {
                const responseData = await fetchleagues();
                setLeagues(responseData);
            } catch (error) {
                console.error("Error fetching leagues:", error);
            }
        };
        fetchLeaguesData();
    }, []);


    const handleItemClick = (item) => {
      setSelectedItem(item.id); // Set the selected league's ID
  };

    useEffect(() => {
        const fetchProjectionsData = async () => {
            try {
                const responseData = await fetchprojections();
                setProjections(responseData);
            } catch (error) {
                console.error("Error fetching projections:", error);
            }
        };
        fetchProjectionsData();
    }, []);
    const [loading,setLoading]=useState(false)
    const retryfetchProjections=()=>{
      setLoading(true)
      const fetchProjectionsData = async () => {
        try {
            const responseData = await fetchprojections();
            setProjections(responseData);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching projections:", error);
            setLoading(false)

        }
        finally{
          setLoading(false)

        }
    };
    fetchProjectionsData();
    }


    // Filter projections based on the selected league ID
    const filteredProjections = projections.filter(projection => projection.league_id === selectedItem);

    return (
        <div>
            <Lines leagues={leagues}  selectedItem={selectedItem} handleItemClick={handleItemClick} />
            <Expectation loading={loading} retryfetchProjections={retryfetchProjections} projections={filteredProjections} />
        </div>
    );
}

export default Home;
