import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  backgroundColor: '#040613',
  padding: '8px',
  overflowX: 'auto',
  justifyContent: 'center',
  marginTop: '20px',
  alignItems: 'center',
}));

const StyledItem = styled(Box)(({ isSelected }) => ({
  display: 'flex',
  flexDirection: 'column', // Align items in a column
  alignItems: 'center',
  backgroundColor: isSelected ? '#26004d' : '#111320',
  color: '#FFFFFF',
  padding: '4px 8px',
  borderRadius: '4px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',
  width: '120px',
  height: '100px',
  textAlign: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
}));

export default function Lines({ leagues, handleItemClick, selectedItem }) {
  return (
    <StyledBox>
      {leagues.map((item) => (
        <StyledItem
          key={item.name}
          onClick={() => handleItemClick(item)}
          isSelected={(selectedItem || leagues[0].id) === (item.id) }
        >
          {item.image_url ? (
            <img
              src={item.image_url}
              alt='game img'
              style={{
                maxWidth: '100%',
                maxHeight: '70%',
                objectFit: 'contain',
                marginBottom: '8px',
              }}
            />
          ) : null}
          <Typography variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: item.image_url ? '8px' : '0',
            }}
          >
            {item.name}
          </Typography>
        </StyledItem>
      ))}
    </StyledBox>
  );
}
