import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const NoDataComponent = ({ onRetry,loading }) => {
    return (
        <>
         {loading ? (
              <Box
              sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  textAlign: 'center',
                  padding: 4,
                  borderRadius: 2,
                  boxShadow: 1,
              }}
          >
        <CircularProgress/>
          </Box>
        ):(
            <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                padding: 4,
                borderRadius: 2,
                boxShadow: 1,
            }}
        >
            <SentimentDissatisfiedIcon sx={{ fontSize: 60, color: '#9e9e9e', mb: 2 }} />
            <Typography variant="h6" sx={{ color: '#757575', mb: 1 }}>
                No Data Available
            </Typography>
            <Typography variant="body2" sx={{ color: '#9e9e9e', mb: 3 }}>
                It seems that there is no data to display here at the moment. Please check back later.
            </Typography>
            <Button variant="contained" color="primary" onClick={onRetry}>
                Retry
            </Button>
        </Box>  
        )}
        </>
       
       
    );
};

export default NoDataComponent;
