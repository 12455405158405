import { axiosInstance ,axiosInstanceServices} from '../baseUrl'; // Assuming axiosInstance is imported correctly
export const fetchleagues = async (language) => {
    try {
      const response = await axiosInstance.get(`/leagues`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const fetchprojections = async (language) => {
    try {
      const response = await axiosInstance.get(`/projections`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };